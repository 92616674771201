.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes changeOpacity {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.7;
  }
  60% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.buttonAnimation {
  animation: changeOpacity 300ms ease-out;
  animation-fill-mode: backwards;
}
